import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import VerticalAlign from "../components/verticalAlign"
import AccountHeader from "../components/accountHeader"
import AccountContainer from "../components/accountContainer"
import AccountSecondary from "../components/accountSecondary"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import theme from "../theme"

const Styles = styled.div`
  .my-item {
    display: grid;
    grid-template-columns: 40px calc(100% - 84px) 20px;
    grid-gap: 12px;
    padding: 16px 18px;
    padding-right: 4px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;

    .title {
      font-size: 18px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .my-image {
    span {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #ccc;
      display: block;
    }

    .gatsby-image-wrapper {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #ccc;
      display: block;
    }
  }

  .my-icons {
    float: right;

    svg {
      width: 24px;
      cursor: pointer;
    }
  }

  .your-cards-title {
    padding: 16px 18px;
    font-weight: bold;
    font-size: 18px;
    
  }

  .your-cards {
  }

  .card-background {
    background: #f1f1f1;
    padding: 40px 20px;
  }
`

export default class extends React.Component {
  constructor(props) {
    super()
    this.state = {}
    this.state.yourCards = [
      {
        id: "noad@stokes.com",
        name: "Noah Stokes",
        first: "Noah",
        img: props.data.noah.childImageSharp.fluid,
        companyLogo: props.data.noahLogo.childImageSharp.fluid,
        company: "Last Gard",
        title: "Creative Director",
        cardImages: {
          front: props.data.cardFront.childImageSharp.fluid,
          back: props.data.cardBack.childImageSharp.fluid,
        },
        links: [
          {
            title: "Linkedin",
            url: "www.linkedin.com/in/noahstokes",
            img: props.data.linkedin.childImageSharp.fluid,
          },

          {
            title: "Twitter",
            url: "@noahstokes",
            img: props.data.twitter.childImageSharp.fluid,
          },

          {
            title: "Facebook",
            url: "www.facebook.com/people/stokesn",
            img: props.data.facebook.childImageSharp.fluid,
          },

          {
            title: "Instagram",
            url: "@noahs",
            img: props.data.instagram.childImageSharp.fluid,
          },

          {
            title: "Skype",
            url: "noah.stokes",
            img: props.data.skype.childImageSharp.fluid,
          },
        ],
        bio:
          "Veteran designer with over 17 years experience. Passionate about product design, user experience, and building great products–without compromise. Noah has a diverse background in client services consulting, with over a decade focusing on design, development, and project management.",
      },

      {
        id: "arthur@vandelay.com",
        name: "Arthur Vandelay",
        first: "Arthur",
        img: props.data.vandelay.childImageSharp.fluid,
        companyLogo: props.data.vandelayLogo.childImageSharp.fluid,
        company: "District of New York",
        title: "Judge",
        cardImages: {
          front: props.data.cardFront.childImageSharp.fluid,
          back: props.data.cardBack.childImageSharp.fluid,
        },
        links: [
          {
            title: "Linkedin",
            url: "www.linkedin.com/in/arthurvandelay",
            img: props.data.linkedin.childImageSharp.fluid,
          },

          {
            title: "Facebook",
            url: "www.facebook.com/people/arthurvandelay",
            img: props.data.facebook.childImageSharp.fluid,
          },

          {
            title: "Twitter",
            url: "@arthurvandelay",
            img: props.data.twitter.childImageSharp.fluid,
          },

          {
            title: "Skype",
            url: "arthur.vandelay",
            img: props.data.skype.childImageSharp.fluid,
          },

          {
            title: "Instagram",
            url: "@arthurvandelay",
            img: props.data.instagram.childImageSharp.fluid,
          },
        ],
        bio:
          "Veteran judge with over 67 years experience. Passionate about law, society, and conducting business. Arthur...",
      },
    ]

    this.state.teamCards = [
      {
        id: "noad@stokes.com",
        name: "Noah Stokes",
        first: "Noah",
        img: props.data.noah.childImageSharp.fluid,
        companyLogo: props.data.noahLogo.childImageSharp.fluid,
        company: "Last Gard",
        title: "Creative Director",
        cardImages: {
          front: props.data.cardFront.childImageSharp.fluid,
          back: props.data.cardBack.childImageSharp.fluid,
        },
        links: [
          {
            title: "Linkedin",
            url: "www.linkedin.com/in/noahstokes",
            img: props.data.linkedin.childImageSharp.fluid,
          },

          {
            title: "Twitter",
            url: "@noahstokes",
            img: props.data.twitter.childImageSharp.fluid,
          },

          {
            title: "Facebook",
            url: "www.facebook.com/people/stokesn",
            img: props.data.facebook.childImageSharp.fluid,
          },

          {
            title: "Instagram",
            url: "@noahs",
            img: props.data.instagram.childImageSharp.fluid,
          },

          {
            title: "Skype",
            url: "noah.stokes",
            img: props.data.skype.childImageSharp.fluid,
          },
        ],
        bio:
          "Veteran designer with over 17 years experience. Passionate about product design, user experience, and building great products–without compromise. Noah has a diverse background in client services consulting, with over a decade focusing on design, development, and project management.",
      },

      {
        id: "arthur@vandelay.com",
        name: "Arthur Vandelay",
        first: "Arthur",
        img: props.data.vandelay.childImageSharp.fluid,
        companyLogo: props.data.vandelayLogo.childImageSharp.fluid,
        company: "District of New York",
        title: "Judge",
        cardImages: {
          front: props.data.cardFront.childImageSharp.fluid,
          back: props.data.cardBack.childImageSharp.fluid,
        },
        links: [
          {
            title: "Linkedin",
            url: "www.linkedin.com/in/arthurvandelay",
            img: props.data.linkedin.childImageSharp.fluid,
          },

          {
            title: "Facebook",
            url: "www.facebook.com/people/arthurvandelay",
            img: props.data.facebook.childImageSharp.fluid,
          },

          {
            title: "Twitter",
            url: "@arthurvandelay",
            img: props.data.twitter.childImageSharp.fluid,
          },

          {
            title: "Skype",
            url: "arthur.vandelay",
            img: props.data.skype.childImageSharp.fluid,
          },

          {
            title: "Instagram",
            url: "@arthurvandelay",
            img: props.data.instagram.childImageSharp.fluid,
          },
        ],
        bio:
          "Veteran judge with over 67 years experience. Passionate about law, society, and conducting business. Arthur...",
      },
    ]
  }

  componentDidMount() {
    if (typeof window !== undefined) {
      window.onresize = this.handleResize.bind(this)
    }
  }

  handleResize(e) {
    if (window.innerWidth < 700) {
      this.setState({
        trigger: false,
      })
    }
  }

  setYourLink(e) {
    let contact = this.state.yourCards.filter(el => {
      return el.id == e.currentTarget.dataset.id
    })[0]

    this.setState({
      current: <Card parent={this} contact={contact} />,
      active: "active",
    })
  }

  setTeamLink(e) {
    let contact = this.state.teamCards.filter(el => {
      return el.id == e.currentTarget.dataset.id
    })[0]

    this.setState({
      current: <Card parent={this} contact={contact} />,
      active: "active",
    })
  }

  addUser() {
    this.setState({
      current: <AddUser parent={this} />,
      active: "active",
    })
  }

  reset() {
    this.setState({
      current: "",
      active: "",
    })
  }

  yourLink(el) {
    return (
      <div
        className="my-item"
        data-id={el.id}
        onClick={this.setYourLink.bind(this)}
        key={el.id}
      >
        <div className="my-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="46"
            viewBox="0 0 30 46"
            fill="none"
          >
            <rect
              x="1"
              y="1"
              width="28"
              height="44"
              rx="2"
              stroke="#2A2A2A"
              stroke-width="2"
            />
            <path
              d="M0 33H30V43C30 44.6569 28.6569 46 27 46H3C1.34315 46 0 44.6569 0 43V33Z"
              fill="#2A2A2A"
            />
          </svg>
        </div>
        <div>
          <div className="title">{el.name}</div>

          <div className="subtitle">{el.title}</div>
        </div>

        <div>
          <VerticalAlign>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="20"
              viewBox="0 0 8 20"
              fill="none"
            >
              <rect
                x="1.55359"
                y="19.5166"
                width="1.79394"
                height="11.6606"
                rx="0.896969"
                transform="rotate(-150 1.55359 19.5166)"
                fill="#C4C4C4"
              />
              <rect
                x="0.00012207"
                y="0.896973"
                width="1.79394"
                height="11.6606"
                rx="0.896969"
                transform="rotate(-30 0.00012207 0.896973)"
                fill="#C4C4C4"
              />
            </svg>
          </VerticalAlign>
        </div>
      </div>
    )
  }

  teamLink(el) {
    return (
      <div
        className="my-item"
        data-id={el.id}
        onClick={this.setTeamLink.bind(this)}
        key={el.id}
      >
        <div className="my-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="46"
            viewBox="0 0 30 46"
            fill="none"
          >
            <rect
              x="1"
              y="1"
              width="28"
              height="44"
              rx="2"
              stroke="#2A2A2A"
              stroke-width="2"
            />
            <path
              d="M0 33H30V43C30 44.6569 28.6569 46 27 46H3C1.34315 46 0 44.6569 0 43V33Z"
              fill="#2A2A2A"
            />
          </svg>
        </div>
        <div>
          <div className="title">{el.name}</div>

          <div className="subtitle">{el.title}</div>
        </div>

        <div>
          <VerticalAlign>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="20"
              viewBox="0 0 8 20"
              fill="none"
            >
              <rect
                x="1.55359"
                y="19.5166"
                width="1.79394"
                height="11.6606"
                rx="0.896969"
                transform="rotate(-150 1.55359 19.5166)"
                fill="#C4C4C4"
              />
              <rect
                x="0.00012207"
                y="0.896973"
                width="1.79394"
                height="11.6606"
                rx="0.896969"
                transform="rotate(-30 0.00012207 0.896973)"
                fill="#C4C4C4"
              />
            </svg>
          </VerticalAlign>
        </div>
      </div>
    )
  }

  trigger() {
    this.setState({ trigger: !this.state.trigger })
  }

  render() {
    let trigger = this.state.trigger ? "trigger" : ""

    return (
      <Layout>
        <SEO title="Cards" />
        <Styles>
          <AccountHeader page="my-cards" />

          <AccountSecondary>

              <div className="my-title">
                Cards
                <div className="my-icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="28"
                    viewBox="0 0 20 20"
                    fill="none"
                    onClick={this.addUser.bind(this)}
                  >
                    <path
                      d="M0 9.63499C0 9.80339 0 10.27 0.5 10.27C1 10.27 1.78129 10.27 2.42424 10.27H17.5758C18.2187 10.27 19 10.27 19.5 10.27C20 10.27 20 9.80339 20 9.63499C20 9.46658 20 9 19.5 9.00002C19 9.00003 18.2187 9 17.5758 9H2.42424C1.78129 9 1 8.99999 0.5 9.00003C0 8.99999 0 9.46658 0 9.63499Z"
                      fill="#E3C36F"
                    />
                    <path
                      d="M9.63499 20C9.80339 20 10.27 20 10.27 19.5C10.27 19 10.27 18.2187 10.27 17.5758L10.27 2.42424C10.27 1.7813 10.27 1 10.27 0.5C10.27 0 9.80339 0 9.63499 0C9.46658 0 9 0 9.00002 0.5C9.00003 1 9 1.7813 9 2.42424L9 17.5758C9 18.2187 8.99999 19 9.00003 19.5C8.99999 20 9.46658 20 9.63499 20Z"
                      fill="#E3C36F"
                    />
                  </svg>
                </div>
              </div>

              <div className="your-cards-title">Your Cards</div>

              <div className="your-cards">
                <div className="my-links">
                  {this.state.yourCards.map(this.yourLink.bind(this))}
                </div>
              </div>

              <div className="your-cards-title">Team Cards</div>

              <div className="your-cards">
                {this.state.teamCards.map(this.teamLink.bind(this))}
              </div>
          </AccountSecondary>

          <AccountContainer className={trigger + " " + this.state.active}>
            {this.state.current}
          </AccountContainer>
        </Styles>
      </Layout>
    )
  }
}

class Card extends React.Component {
  constructor(props) {
    super()
    this.state = {}
    this.state.cardImage = props.contact.cardImages.front
    this.state.front = true
  }

  componentWillUnmount() {
    this.setState({
      cardImage: this.props.contact.cardImages.front,
      front: true,
    })
  }

  switch() {
    if (this.state.front) {
      this.setState({
        cardImage: this.props.contact.cardImages.front,
        front: !this.state.front,
      })
    } else {
      this.setState({
        cardImage: this.props.contact.cardImages.back,
        front: !this.state.front,
      })
    }
  }

  exit() {
    this.props.parent.reset()
  }

  render() {
    return (
      <div className="white-background">
        <div className="my-main">
          <div className="title">

          <div className="closer" onClick={this.exit.bind(this)}>
          <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M8.18698 7.6708C8.0679 7.78989 7.73796 8.11983 8.09152 8.47338C8.44506 8.82694 8.99755 9.37937 9.45218 9.83401L20.1659 20.5477C20.6206 21.0024 21.173 21.5548 21.5266 21.9084C21.8801 22.2619 22.21 21.932 22.3291 21.8129C22.4482 21.6939 22.7781 21.3639 22.4246 21.0104C22.071 20.6569 21.5186 20.1044 21.0639 19.6497L10.3502 8.936C9.89555 8.48137 9.3431 7.9289 8.98952 7.57537C8.63599 7.2218 8.30607 7.55172 8.18698 7.6708Z"
                  fill="#E3C36F"
                />
                <path
                  d="M7.67077 21.813C7.78986 21.932 8.1198 22.262 8.47335 21.9084C8.82691 21.5549 9.37934 21.0024 9.83398 20.5478L20.5477 9.83402C21.0023 9.37939 21.5548 8.82693 21.9084 8.47337C22.2619 8.11982 21.932 7.7899 21.8129 7.67082C21.6938 7.55174 21.3639 7.22182 21.0104 7.57539C20.6568 7.92895 20.1043 8.48138 19.6497 8.93602L8.93597 19.6498C8.48134 20.1044 7.92887 20.6568 7.57534 21.0104C7.22177 21.3639 7.55169 21.6939 7.67077 21.813Z"
                  fill="#E3C36F"
                />
              </svg>
            </div>
            {this.props.contact.first}'s Card
            
          </div>

          <div className="card-background">
            <Img
              className="my-card-image"
              onClick={this.switch.bind(this)}
              fluid={this.state.cardImage}
              alt={this.props.contact.name}
            />
          </div>
        </div>
      </div>
    )
  }
}

class AddUser extends React.Component {
  exit() {
    this.props.parent.reset()
  }

  render() {
    return (
      <div className="white-background">
        <div className="my-main">
          <div className="title">

          <div className="closer" onClick={this.exit.bind(this)}>
          <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M8.18698 7.6708C8.0679 7.78989 7.73796 8.11983 8.09152 8.47338C8.44506 8.82694 8.99755 9.37937 9.45218 9.83401L20.1659 20.5477C20.6206 21.0024 21.173 21.5548 21.5266 21.9084C21.8801 22.2619 22.21 21.932 22.3291 21.8129C22.4482 21.6939 22.7781 21.3639 22.4246 21.0104C22.071 20.6569 21.5186 20.1044 21.0639 19.6497L10.3502 8.936C9.89555 8.48137 9.3431 7.9289 8.98952 7.57537C8.63599 7.2218 8.30607 7.55172 8.18698 7.6708Z"
                  fill="#E3C36F"
                />
                <path
                  d="M7.67077 21.813C7.78986 21.932 8.1198 22.262 8.47335 21.9084C8.82691 21.5549 9.37934 21.0024 9.83398 20.5478L20.5477 9.83402C21.0023 9.37939 21.5548 8.82693 21.9084 8.47337C22.2619 8.11982 21.932 7.7899 21.8129 7.67082C21.6938 7.55174 21.3639 7.22182 21.0104 7.57539C20.6568 7.92895 20.1043 8.48138 19.6497 8.93602L8.93597 19.6498C8.48134 20.1044 7.92887 20.6568 7.57534 21.0104C7.22177 21.3639 7.55169 21.6939 7.67077 21.813Z"
                  fill="#E3C36F"
                />
              </svg>
            </div>
            Add User
            
          </div>

          <div style={{marginBottom: 14, fontSize: 18}}>
            <b>
              Invite
            </b>
          </div>

          <form>
            <div className="field">
              <input
                className="input"
                type="text"
                placeholder="First Name"
                required
              />
            </div>

            <div className="field">
              <input
                className="input"
                type="text"
                placeholder="Last Name"
                required
              />
            </div>

            <div className="field">
              <input
                className="input"
                type="email"
                placeholder="Email Address"
                required
              />
            </div>

            <div className="field">
              <input
                className="input"
                type="text"
                placeholder="Role"
                required
              />
            </div>

            <div style={{ height: 12 }} />

            <button
              className="my-button button is-warning full-width"
              type="submit"
            >
              Send Invite
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export const query = graphql`
  {

    taqi: file(relativePath: { eq: "Taqi.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    vish: file(relativePath: { eq: "Vish.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    pavan: file(relativePath: { eq: "Pavan.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cardFront: file(relativePath: { eq: "card-front.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cardBack: file(relativePath: { eq: "card-back.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    noah: file(relativePath: { eq: "noah.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    noahLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vandelay: file(relativePath: { eq: "vandelay.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vandelayLogo: file(relativePath: { eq: "vandelay-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    skype: file(relativePath: { eq: "skype.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
